@font-face {
    font-display: swap;
    font-family: 'Gotham SSm';
    src: url('GothamSSm-Black.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-display: swap;
    font-family: 'Gotham SSm Book';
    src: url('GothamSSm-BookItalic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-display: swap;
    font-family: 'Gotham SSm Book';
    src: url('GothamSSm-Book.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-display: swap;
    font-family: 'Gotham SSm';
    src: url('GothamSSm-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-display: swap;
    font-family: 'Gotham SSm';
    src: url('GothamSSm-BlackItalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-display: swap;
    font-family: 'Gotham SSm';
    src: url('GothamSSm-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-display: swap;
    font-family: 'Gotham SSm';
    src: url('GothamSSm-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-display: swap;
    font-family: 'Gotham SSm';
    src: url('GothamSSm-MediumItalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-display: swap;
    font-family: 'Gotham SSm';
    src: url('GothamSSm-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-display: swap;
    font-family: 'Gotham SSm Extra';
    src: url('GothamSSm-ExtraLightItalic.ttf') format('truetype');
    font-weight: 200;
    font-style: italic;
}

@font-face {
    font-display: swap;
    font-family: 'Gotham SSm';
    src: url('GothamSSm-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-display: swap;
    font-family: 'Gotham SSm Extra';
    src: url('GothamSSm-ExtraLight.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
}

