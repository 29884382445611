@font-face {
    font-display: swap;
    font-family: 'Gotham';
    src: url('Gotham-Black.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-display: swap;
    font-family: 'Gotham Book';
    src: url('Gotham-Book.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-display: swap;
    font-family: 'Gotham Book';
    src: url('Gotham-BookItalic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-display: swap;
    font-family: 'Gotham';
    src: url('Gotham-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-display: swap;
    font-family: 'Gotham';
    src: url('Gotham-BlackItalic.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-display: swap;
    font-family: 'Gotham';
    src: url('Gotham-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-display: swap;
    font-family: 'Gotham';
    src: url('Gotham-MediumItalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-display: swap;
    font-family: 'Gotham';
    src: url('Gotham-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-display: swap;
    font-family: 'Gotham Ultra';
    src: url('Gotham-Ultra.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-display: swap;
    font-family: 'Gotham';
    src: url('Gotham-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-display: swap;
    font-family: 'Gotham';
    src: url('Gotham-ThinItalic.ttf') format('truetype');
    font-weight: 100;
    font-style: italic;
}

@font-face {
    font-display: swap;
    font-family: 'Gotham';
    src: url('Gotham-Thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-display: swap;
    font-family: 'Gotham';
    src: url('Gotham-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-display: swap;
    font-family: 'Gotham Ultra';
    src: url('Gotham-UltraItalic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-display: swap;
    font-family: 'Gotham Extra';
    src: url('Gotham-ExtraLightItalic.ttf') format('truetype');
    font-weight: 200;
    font-style: italic;
}

@font-face {
    font-display: swap;
    font-family: 'Gotham Extra';
    src: url('Gotham-ExtraLight.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
}

