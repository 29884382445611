html,
body {
  padding: 0;
  margin: 0;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

@keyframes fadein {
  from { opacity: 0; }
  to { opacity: 1; }
}

body {
  animation: fadein 450ms;
}

html {
  /*font-size: 0.33rem;*/
  font-size: 0.66rem;
}

/*
@media (min-width: 481px) {
  html {
    font-size: 0.33rem;
  }
}
*/

@media (min-width: 740px) {
  html {
    font-size: 0.66rem;
  }
}

@media (min-width: 992px) {
  html {
    font-size: 1rem;
  }
}

html {
  scroll-behavior: smooth;
}
@media (prefers-reduced-motion: reduce) {
  html {
    scroll-behavior: auto;
  }
}